
import React, { useState, useEffect } from 'react';
import firebase from '../../components/firebase';
import Login from '../Login';
import Home from '../Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

export default function App() {

	const [firebaseInitialized, setFirebaseInitialized] = useState(false)

	useEffect(() => {
		firebase.isInitialized().then(val => {
			setFirebaseInitialized(val)
		})
	})

	return firebaseInitialized !== false ? (
			<Router>
				<Switch>
					<Route exact path="/" component={Login} />
					<Route exact path="/home" component={Home} />
				</Switch>
			</Router>
	) : <div id="loader">Loading ...</div>
}