import React, {useState, useEffect} from "react";

import { Link, withRouter } from 'react-router-dom';

import firebase from '../../components/firebase';
import * as EmailValidator from 'email-validator';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// https://fontawesome.com/icons?d=gallery
import { faAt, faLock, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import zxcvbn from 'zxcvbn';
import "./styles.css";


function Login(props) {

  const SIGNIN =   "SIGNIN";
  const REGISTER = "REGISTER";

  const formStateButtonText = {
    SIGNIN: "Register",
    REGISTER: "Sign In"
  };

  const formSubmitButtonText = {
    SIGNIN: "Sign In",
    REGISTER: "Register"
  };

  const [email, setEmail] = useState('');
  const [emailState, setEmailState] = useState('fa-empty');
  const [password, setPassword] = useState('');
  const [passwordState, setPasswordState] = useState('fa-empty'); 
  const [name, setName] = useState('');
  const [nameState, setNameState] = useState('fa-empty'); 
  const [nameLabelClassName, setNameLabelClassName] = useState('hide'); 

  const [signInButtonDisabled, setSignInButtonDisabled] = useState(true);
  const [formState, setFormState] = useState(SIGNIN);
  const [submitButtonText, setSubmitButtonText] = useState(formSubmitButtonText[SIGNIN]);

   useEffect(() => {

    if (email === ''){
      setEmailState('fa-empty');
    } else {
      const valid = EmailValidator.validate(email);
      if (valid === true) {setEmailState('fa-valid');}
      else {setEmailState('fa-invalid');}
    }
    buttonState(); 
  }, [email])

  useEffect(() => {

    if (password === ''){
      setPasswordState('fa-empty');
    } else {
      const strength = zxcvbn(password);
      setPasswordState('fa-valid-' + Math.min(strength.score, 4)); 
    }
    buttonState();

  }, [password]) 

  useEffect(() => {

    if (name.length >= 3){
      setNameState('fa-valid'); 
    } else {
      setNameState('hide');
    }
    buttonState();

  }, [name]) 

  function buttonState() {
    
     if(emailState === 'fa-valid' && (
       passwordState === 'fa-valid-2' ||
       passwordState === 'fa-valid-3' ||
       passwordState === 'fa-valid-4') && (
        formState === SIGNIN || 
        nameState === 'fa-valid'
       ) ){
         setSignInButtonDisabled(false);
       } else {
        setSignInButtonDisabled(true);
       }
  }

  async function login(event) {
    event.preventDefault();
    try {
        if(formState === SIGNIN) {
          await firebase.login(email, password)
        } else {
          await firebase.register(name, email, password)
        }
        props.history.replace('/home')
    } catch(error) {
        alert(error.message)
    }
  }

  function toggleFormType(event){
    event.preventDefault();

     if(formState === REGISTER){
      setNameLabelClassName('hide');
      setFormState(SIGNIN);
      setSubmitButtonText(formSubmitButtonText[SIGNIN]);
     } else {
      setNameLabelClassName('');
      setFormState(REGISTER);
      setSubmitButtonText(formSubmitButtonText[REGISTER]);
     }
  }

  return (

    <div className="login">
      <form className="form">
        <div className="icon">
          <span>&#9733;</span>
        </div>

        <label className={nameLabelClassName}>
          <span>
            <div className={nameState}>
              <FontAwesomeIcon icon={faCheckCircle} />
            </div>
          </span>
          <input
            type="text"
            id="n"
            name="name"
            class="touched invalid"
            value={name} 
            placeholder="Name"
            onChange={e => setName(e.target.value)}
          />
        </label>

        <label>
          <span >
            <div id="ei" className={emailState}>
            <FontAwesomeIcon icon={faAt} />
            </div>
          </span>

          <input
            type="email"
            id="e"
            autofocus=""
            novalidate="novalidate"
            spellcheck="false"
            autocomplete="email"
            placeholder="Email"
            name="email"
            class="touched invalid"
            value={email} 
            onChange={e => setEmail(e.target.value)} 
          />
        </label>

        <label>
          <span>
            <div className={passwordState}>
              <FontAwesomeIcon icon={faLock} />
            </div>
          </span>
          <input
            type="password"
            id="p"
            autocomplete="current-password"
            name="password"
            class="touched invalid"
            placeholder="Password"
            value={password} 
            onChange={e => setPassword(e.target.value)}
          />
        </label>



        <div className="btn-container">

          <div className="left">
            <button
              formnovalidate=""
              type="submit"
              className="text-btn"
              onClick={toggleFormType}
              tabindex="-1">
              <span>{formStateButtonText[formState]}</span>
              <div class="spinny" />
            </button>
          </div>

          <div className="right">
            <button
              formnovalidate=""
              type="submit"
              className="btn"
              tabindex="-1"
              onClick={login}
              disabled={signInButtonDisabled}>
              <span>{submitButtonText}</span>
              <div class="spinny" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );

}

export default withRouter(Login)