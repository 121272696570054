import React, {useState} from "react";
import { withRouter } from 'react-router-dom';
import FitText from '@kennethormandy/react-fittext'

import { Button, Header, Icon, Image, Menu, Segment, Sidebar } from 'semantic-ui-react'

import firebase from '../../components/firebase';

import "./styles.css"

function Home(props) {

  const [open, setOpen] = useState(false);

  const handleClick = () => {setOpen(!open);};
  const handleShowClick = () => {setOpen(true);};
  const handleSidebarHide = () => {setOpen(false);};

  return (

  <div>
    <Button.Group>
       <Button disabled={open} onClick={handleClick}>
             <Icon name='list' size='large' />
          </Button>
    </Button.Group>

    <Sidebar.Pushable as={Segment}>

      <Sidebar
        as={Menu}
        animation='overlay'
        icon='labeled'
        inverted
        vertical
        onHide={handleSidebarHide}
        visible={open}
        width='thin'
      >
        <Menu.Item as='a'>
          <Icon name='home' />
          Home
        </Menu.Item>
        <Menu.Item as='a'>
          <Icon name='question circle' />
          Help
        </Menu.Item>
        <Menu.Item as='a'>
          <Icon name='tty' />
          Console
        </Menu.Item>
      </Sidebar>

      <Sidebar.Pusher className='content'>
        <Segment className='centre' basic> 
            <FitText>
                {firebase.getCurrentUsername()}
            </FitText>
        </Segment>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  </div>

  )
}

export default withRouter(Home)
